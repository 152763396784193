import qs from 'qs';
import { createRouter, createWebHistory, LocationQuery, RouteRecordRaw } from 'vue-router';
import Analysis from '@/features/analysis';
import Bookings from '@/features/bookings';
import BookService from '@/features/book-service';
import CountryFiles from '@/features/country-files';
import Dashboard from '@/features/dashboard';
import EventsList from '@/features/events-list';
import Map from '@/features/map';
import Network from '@/features/network';
import Profile from '@/features/profile';
import Profiles from '@/features/profiles';
import Settings from '@/features/settings';
import Trips from '@/features/trips';

const routes: Array<RouteRecordRaw> = [{
  path: '/',
  name: 'home',
  redirect: { name: 'network.v-network-graph' },
  children: [
    Analysis,
    Bookings,
    BookService,
    CountryFiles,
    Dashboard,
    EventsList,
    Map,
    Network,
    Profile,
    Profiles,
    Settings,
    Trips,
  ],
}];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  parseQuery(query) {
    return qs.parse(query) as LocationQuery;
  },
  stringifyQuery(query) {
    return qs.stringify({ ...query });
  },
});

export default router;
