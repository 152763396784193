import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.component)
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.title)
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("ul", {
      class: _normalizeClass(_ctx.$style.legend)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.series, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(_ctx.$style.legend__item)
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.legend__color),
            style: _normalizeStyle({ background: item.color })
          }, null, 6),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.legend__label)
          }, _toDisplayString(item.label), 3)
        ], 2))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.component__diagram)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.pie),
        ref: "pieRef"
      }, null, 2),
      (_ctx.colors[_ctx.index])
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.tooltip),
            ref: "tooltipRef"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.tooltip__color),
              style: _normalizeStyle({ background: _ctx.colors[_ctx.index] })
            }, null, 6),
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.tooltip__label)
            }, _toDisplayString(_ctx.labels[_ctx.index]), 3)
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}