const AppHeader = () => import(/* webpackChunkName: "trips" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "trips" */ '@/components/app-footer/index.vue');
const Trips = () => import(/* webpackChunkName: "trips" */ '@/features/trips/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Trips,
    footer: AppFooter,
  },
  name: 'trips',
  path: '/trips',
};
