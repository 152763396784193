const AppHeader = () => import(/* webpackChunkName: "settings" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "settings" */ '@/components/app-footer/index.vue');
const Settings = () => import(/* webpackChunkName: "settings" */ '@/features/settings/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Settings,
    footer: AppFooter,
  },
  name: 'settings',
  path: '/settings',
};
