const AppHeader = () => import(/* webpackChunkName: "bookings" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "bookings" */ '@/components/app-footer/index.vue');
const Bookings = () => import(/* webpackChunkName: "bookings" */ '@/features/bookings/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Bookings,
    footer: AppFooter,
  },
  name: 'bookings',
  path: '/bookings',
};
