const AppHeader = () => import(/* webpackChunkName: "events" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "events" */ '@/components/app-footer/index.vue');
const EventsList = () => import(/* webpackChunkName: "events" */ '@/features/events-list/index.vue');

export default {
  components: {
    header: AppHeader,
    default: EventsList,
    footer: AppFooter,
  },
  name: 'event-lists',
  path: '/events-list',
};
