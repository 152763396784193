const AppHeader = () => import(/* webpackChunkName: "country-files" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "country-files" */ '@/components/app-footer/index.vue');
const CountryFiles = () => import(/* webpackChunkName: "country-files" */ '@/features/country-files/index.vue');
const List = () => import(/* webpackChunkName: "country-files" */ '@/features/country-files/list/index.vue');
const File = () => import(/* webpackChunkName: "country-files" */ '@/features/country-files/file/index.vue');

export default {
  components: {
    header: AppHeader,
    default: CountryFiles,
    footer: AppFooter,
  },
  name: 'country-files',
  path: '/country-files',
  redirect: { name: 'country-files.list' },
  children: [{
    component: List,
    name: 'country-files.list',
    path: 'list',
  }, {
    component: File,
    name: 'country-files.file',
    path: ':id',
  }],
};
