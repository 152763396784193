<template>
  <helper-dialog
    width="800"
    ref="helperDialogRef"
    @closed="handleDialogClosed"
  >
    <template #header>
      <span>Events</span>
    </template>
    <template #default="{ dialogOpen }">
      <div v-if="dialogOpen">
        <el-tabs
          v-model="tabs"
          type="border-card"
        >
          <el-tab-pane
            :name="Tab.content"
            label="MapEvent content"
          >
            <div v-html="content[locale]"></div>
          </el-tab-pane>
          <el-tab-pane
            :name="Tab.travellers"
            label="Impacted travellers"
          >
            <el-form size="default">
              <el-row :gutter="12">
                <el-col :span="12">
                  <el-form-item>
                    <el-select
                      v-model="radius"
                      :teleported="true"
                      clearable
                      placeholder="Radius"
                    >
                      <el-option
                        v-for="(item, index) in ['5', '10', '30']"
                        :key="index"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <el-checkbox
                      v-model="wholeCountry"
                      label="Whole country"
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <helper-table
              v-if="tabs === Tab.travellers"
              :data="tableData"
              :columns="tableColumns"
              size="small"
            ></helper-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
    <template #footer="{ close }">
      <el-button
        type="primary"
        @click="close"
      >
        <span>Close</span>
      </el-button>
    </template>
  </helper-dialog>
</template>
<script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { HelperDialogInstance, Language } from 'mph-helper';
  import i18n from '@/i18n';

  const Tab = {
    content: 'content',
    travellers: 'travellers',
  };

  export default defineComponent({
    setup() {
      const helperDialogRef = ref<HelperDialogInstance>();
      const tabs = ref<string>(Tab.content);
      const radius = ref<string>();
      const wholeCountry = ref(false);
      const title = ref<Language>(new Language());
      const content = ref<Language>(new Language());
      const tableColumns = [{
        prop: 'firstName',
        label: 'First name',
        sortable: true,
      }, {
        prop: 'surname',
        label: 'Surname',
        sortable: true,
      }];
      const tableData = [{
        firstName: 'Jon',
        surname: 'Snow',
      }, {
        firstName: 'Tyrion',
        surname: 'Lanister',
      }];
      const { locale } = i18n.global;
      const open = (payload: { title: Language, content: Language }) => {
        title.value = new Language(payload.title);
        content.value = new Language(payload.content);
        helperDialogRef.value?.open();
      };
      const handleDialogClosed = () => {
        title.value = new Language();
        content.value = new Language();
        tabs.value = Tab.content;
      };
      return {
        Tab,
        helperDialogRef,
        tabs,
        radius,
        wholeCountry,
        title,
        content,
        tableColumns,
        tableData,
        locale,
        open,
        handleDialogClosed,
      };
    },
  });
</script>
