import { createApp } from 'vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import '@/styles/index.scss';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import {
  HelperAppFooter,
  HelperAppHeader,
  HelperDialog,
  HelperFormError,
  HelperFormSchema,
  HelperLink,
  HelperQuerySearch,
  HelperTable,
} from 'mph-helper';
import { CommonComponents } from '@/components';
import App from '@/App.vue';
import store from '@/store';
import i18n from '@/i18n';
import router from '@/router';

export const app = createApp(App);

Object
  .entries(CommonComponents)
  .forEach(([key, value]) => {
    app.component(key, value);
  });

Object
  .entries(ElementPlusIconsVue)
  .forEach(([key, value]) => {
    app.component(`Icon${key}`, value);
  });

(async () => {
  app.use(ElementPlus);
  app.use(HelperAppFooter);
  app.use(HelperAppHeader);
  app.use(HelperDialog);
  app.use(HelperFormError);
  app.use(HelperFormSchema);
  app.use(HelperLink);
  app.use(HelperQuerySearch);
  app.use(HelperTable);
  app.use(VueQueryPlugin, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          staleTime: 5000,
          refetchOnWindowFocus: false,
          retry: 0,
        },
      },
    },
  });
  app.use(store);
  app.use(i18n);
  app.use(router);
  app.mount('#app');
})();
