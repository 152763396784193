const AppHeader = () => import(/* webpackChunkName: "profiles" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "profiles" */ '@/components/app-footer/index.vue');
const Profiles = () => import(/* webpackChunkName: "profiles" */ '@/features/profiles/index.vue');
const Search = () => import(/* webpackChunkName: "profiles" */ '@/features/profiles/search/index.vue');
const Profile = () => import(/* webpackChunkName: "profiles" */ '@/features/profiles/profile/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Profiles,
    footer: AppFooter,
  },
  name: 'profiles',
  path: '/profiles',
  redirect: { name: 'profiles.search' },
  children: [{
    component: Search,
    name: 'profiles.search',
    path: 'search',
  }, {
    component: Profile,
    name: 'profiles.profile',
    path: 'profile',
  }],
};
