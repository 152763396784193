<template>
  <div :class="$style.component">
    <div
      v-if="title"
      :class="$style.title"
    >
      <span>{{ title }}</span>
    </div>
    <ul :class="$style.legend">
      <li
        v-for="(item, index) in series"
        :key="index"
        :class="$style.legend__item"
      >
      <span
        :class="$style.legend__color"
        :style="{ background: item.color }"
      ></span>
        <span :class="$style.legend__label">{{ item.label }}</span>
      </li>
    </ul>
    <div :class="$style.component__diagram">
      <div
        :class="$style.pie"
        ref="pieRef"
      ></div>
      <div
        v-if="colors[index]"
        :class="$style.tooltip"
        ref="tooltipRef"
      >
      <span
        :class="$style.tooltip__color"
        :style="{ background: colors[index] }"
      ></span>
        <span :class="$style.tooltip__label">{{ labels[index] }}</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, ref, computed, PropType, onMounted, onUnmounted, nextTick } from 'vue';
  import { PieChart, deserialize } from 'chartist';

  type SeriesType = {
    color: string,
    value: number,
    label: string,
  }

  export default defineComponent({
    props: {
      title: {
        type: String,
      },
      series: {
        type: Array as PropType<Array<SeriesType>>,
        required: true,
      },
    },
    setup(props) {
      const pieRef = ref<HTMLElement>();
      const tooltipRef = ref<HTMLElement>();
      const pie = ref<PieChart | null>(null);
      const index = ref<number>(-1);
      const colors = computed(() => props.series.map((item) => item.color));
      const values = computed(() => props.series.map((item) => item.value));
      const labels = computed(() => props.series.map((item) => item.label));
      const handleMouseMove = (event: MouseEvent) => {
        if (tooltipRef.value && pieRef.value) {
          const pieWidth = pieRef.value.clientWidth;
          const { clientWidth, clientHeight } = tooltipRef.value;
          const { offsetX, offsetY } = event;
          Object.assign(tooltipRef.value.style, {
            left: `${offsetX + clientWidth > pieWidth ? offsetX - clientWidth : offsetX}px`,
            top: `${offsetY - clientHeight}px`,
          });
        }
      };
      const handleMouseEnter = (event: MouseEvent) => {
        if (event.target) {
          index.value = deserialize((event.target as HTMLElement).getAttribute('ct:index')) as number;
        }
        nextTick(() => {
          handleMouseMove(event);
        });
      };
      const handleMouseLeave = () => {
        index.value = -1;
      };
      const handlePieAirCreated = () => {
        if (pieRef.value) {
          const paths = [...(pieRef.value.getElementsByTagName('path'))];
          paths.forEach((item, index) => {
            Object.assign(item.style, {
              fill: colors.value[index],
              stroke: 'white',
              strokeWidth: 2,
            });
            item.setAttribute('ct:index', `${index}`);
            item.addEventListener('mouseenter', handleMouseEnter);
            item.addEventListener('mouseleave', handleMouseLeave);
            item.addEventListener('mousemove', handleMouseMove);
          });
        }
      };
      onMounted(() => {
        if (pieRef.value) {
          pie.value = new PieChart(pieRef.value, {
            series: values.value,
          }, {
            showLabel: false,
          });
          pie.value.on('created', handlePieAirCreated);
        }
      });
      onUnmounted(() => {
        pie.value?.detach();
      });
      return {
        pieRef,
        tooltipRef,
        pie,
        index,
        colors,
        labels,
      };
    },
  });
</script>
<style lang="scss" module>
  .component {
    &__diagram {
      position: relative;
    }
    .title {
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      font-size: 1.125rem;
      font-weight: bold;
    }
    .legend {
      margin: 0;
      padding: 0 0 15px 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      list-style: none;

      &__item {
        display: flex;
        align-items: center;
        margin-right: 10px;
        padding: 5px 0;
      }
      &__color {
        flex: 0 0 40px;
        width: 40px;
        height: 12px;
        display: block;
        border: 1px solid rgba(#000, 0.5);
      }
      &__label {
        flex: 0 1 auto;
        margin-left: 5px;
        font-size: 0.75rem;
      }
    }
    .pie {
      svg {
        min-height: 350px;
      }
      :global(.ct-series) {
        stroke: white;
        stroke-width: 2px;
      }
    }
    .tooltip {
      position: absolute;
      margin: -5px 0;
      z-index: 100000;
      padding: 5px;
      background-color: rgba(#333333, 0.95);
      border-radius: 5px;
      pointer-events: none;
      color: white;
      white-space: nowrap;
      display: flex;

      &__color {
        width: 13px;
        height: 13px;
        border: 1px solid white;
        display: inline-block;
      }
      &__label {
        font-size: 0.75rem;
        line-height: 13px;
        margin-left: 2px;
      }
    }
  }
</style>
