<template>
  <el-config-provider size="large">
    <el-container
      v-loading.fullscreen.lock="isFetching"
      :class="$style.component"
      direction="vertical"
    >
      <router-view
        :class="$style.component__header"
        name="header"
      ></router-view>
      <router-view
        :class="$style.component__default"
        name="default"
      ></router-view>
      <router-view
        :class="$style.component__footer"
        name="footer"
      ></router-view>
    </el-container>
  </el-config-provider>
</template>
<script lang="ts">
  import { defineComponent } from 'vue';
  import { useIsFetching } from '@tanstack/vue-query';

  export default defineComponent({
    setup() {
      const isFetching = useIsFetching();
      return {
        isFetching,
      };
    },
  });
</script>
<style lang="scss" module>
  .component {
    height: 100vh;
    overflow: auto;

    &__default {
      flex-grow: 1;
      overflow: auto;
    }
  }
</style>
