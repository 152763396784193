const AppHeader = () => import(/* webpackChunkName: "book-service" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "book-service" */ '@/components/app-footer/index.vue');
const BookService = () => import(/* webpackChunkName: "book-service" */ '@/features/book-service/index.vue');
const BusTicket = () => import(/* webpackChunkName: "book-service" */ '@/features/book-service/bus-ticket/index.vue');
const Car = () => import(/* webpackChunkName: "book-service" */ '@/features/book-service/car/index.vue');
const Ferry = () => import(/* webpackChunkName: "book-service" */ '@/features/book-service/ferry/index.vue');
const TrainTicket = () => import(/* webpackChunkName: "book-service" */ '@/features/book-service/train-ticket/index.vue');
const Visas = () => import(/* webpackChunkName: "book-service" */ '@/features/book-service/visas/index.vue');

export default {
  components: {
    header: AppHeader,
    default: BookService,
    footer: AppFooter,
  },
  name: 'book-service',
  path: '/book-service',
  redirect: { name: 'book-service.car' },
  children: [{
    component: BusTicket,
    name: 'book-service.bus-ticket',
    path: 'bus-ticket',
  }, {
    component: Car,
    name: 'book-service.car',
    path: 'car',
  }, {
    component: Ferry,
    name: 'book-service.ferry',
    path: 'ferry',
  }, {
    component: TrainTicket,
    name: 'book-service.train-ticket',
    path: 'train-ticket',
  }, {
    component: Visas,
    name: 'book-service.visas',
    path: 'visas',
  }],
};
